<template>
  <div class="bitts-simple-tabs" :class="{ 'full-width': fullWidth }">
    <div
      v-for="({ name, icon, spin, value, disabled }, idx) in tabs"
      :key="`${idx}__${name}__tab`"
      class="bitts-simple-tabs__item"
      :class="{
        active: currentValue === value && !disabled,
        'bitts-simple-tabs__disabled': disabled,
      }"
      @click="onTabClick(value, spin, disabled)"
    >
      <!-- Do not use the "all" slot unless you have to wrap everything. Instead
      use the different slots for different content -->
      <slot
        :value="value"
        :disabled="disabled"
        :label="name"
        :icon="icon"
        name="all"
      >
        <slot name="labelPrefix" :value="value" :disabled="disabled" />
        <div v-if="icon" class="bitts-simple-tabs__icon">
          <FontAwesomeIcon
            :icon="icon"
            :class="{
              'text-secondary-accent': currentValue === value,
              'spin-icon': showSpinningIcon && currentValue === value,
            }"
          />
        </div>
        <div class="bitts-simple-tabs__label">
          {{ name }}
        </div>
        <slot name="labelSuffix" :value="value" :disabled="disabled" />
      </slot>
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue';

const props = defineProps({
  currentValue: {
    type: String,
    default: null,
  },
  tabs: {
    type: Array,
    default: () => [],
  },
  fullWidth: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits(['change:tab']);

watch(
  () => props.currentValue,
  () => {
    showSpinningIcon.value = false;
  },
);
const showSpinningIcon = ref(false);

function onTabClick(value, spin, disabled) {
  if (disabled) return;
  if (spin && props.currentValue === value) {
    showSpinningIcon.value = true;
    setTimeout(() => {
      showSpinningIcon.value = false;
    }, 600);
  }
  emit('change:tab', value);
}
</script>

<style scoped lang="pcss">
.bitts-simple-tabs {
  @apply flex select-none;
}

.bitts-simple-tabs.full-width {
  @apply border-b-2 border-neutral-background-disabled;

  /* allow child item border to go on top of the parent border bottom */
  .bitts-simple-tabs__item {
    @apply mb-[-2px];
  }
}

.bitts-simple-tabs:not(.full-width) {
  @apply gap-16;
}

.bitts-simple-tabs.full-width .bitts-simple-tabs__item {
  @apply w-full justify-center;
}

.bitts-simple-tabs__item {
  @apply flex items-center border-b-2 border-transparent pb-8 cursor-pointer;

  &.active {
    @apply border-secondary-accent;
  }
}

.bitts-simple-tabs__label {
  @apply text-neutral-text-weak;
}

.bitts-simple-tabs__disabled {
  @apply cursor-not-allowed;
}

/* hover icon gets the secondary accent purple */
.bitts-simple-tabs__item:hover .bitts-simple-tabs__icon {
  @apply text-secondary-accent;
}

/* active and hover label gets strong text */
.bitts-simple-tabs__item.active .bitts-simple-tabs__label,
.bitts-simple-tabs__item:hover:not(.bitts-simple-tabs__disabled)
  .bitts-simple-tabs__label {
  @apply text-neutral-text-strong;
}

/* not using the simple tailwind spin helper because you can't override the properties */
.spin-icon {
  animation: spin-icon 500ms ease-out;
}

@keyframes spin-icon {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
<style lang="pcss">
.bitts-simple-tabs__icon {
  @apply text-neutral-400 w-16 mr-8 hidden sm:block;
}
/* hover icon gets the info accent blue */
.bitts-simple-tabs__item:hover .bitts-simple-tabs__icon {
  @apply text-secondary-accent;
}
</style>
